import * as auth from 'auth-provider'
const apiURL = process.env.REACT_APP_API_URL

async function client(
  endpoint,
  {data, token, headers: customHeaders, ...customConfig} = {},
) {
  let token_config = {
    Authorization: token ? `Bearer ${token}` : undefined,
    'Content-Type': data ? 'application/json' : undefined,
    ...customHeaders,
  };
  if(token === undefined){
    token_config = {
      'Content-Type': data ? 'application/json' : undefined,
      ...customHeaders,
    };
  }
  const config = {
    method: data ? 'POST' : 'GET',
    body: data ? JSON.stringify(data) : undefined,
    headers: token_config,
    ...customConfig,
  }
  
  return window.fetch(`${apiURL}/${endpoint}`, config).then(async response => {
    if (response.status === 401) {
      
      await auth.logout()
      // refresh the page for them
      console.log("endpoint",endpoint)
      // window.location.assign('/login')
      return Promise.reject({message: 'Please re-authenticate.',code:response.status})
    }
    const data = await response.json()
    if (response.ok) {
      return data
    } else {
      return Promise.reject(data)
    }
  })
}

export {client}
