import * as React from 'react'
import { useAuth } from './context/auth-context'
import { FullPageSpinner } from './components/lib'
import { AlertProvider } from 'context/alert-context'

const AuthenticatedApp = React.lazy(() =>
  import(/* webpackPrefetch: true */ './authenticated-app.weekpark'),
)
// const UnauthenticatedApp = React.lazy(() => import('./unauthenticated-app.weekpark'))

function App() {
  const { user } = useAuth()

  React.useEffect(() => {
    if (user && (window.location.href.indexOf('register') !== -1
      || window.location.href.indexOf('login') !== -1)) {
      window.location.assign(window.location.origin + '/search')
    }
  }, [user])
  return (
    <React.Suspense fallback={<FullPageSpinner />}>
      <AlertProvider>
        <AuthenticatedApp />
        {/* {user ?  : <UnauthenticatedApp />} */}
      </AlertProvider>
    </React.Suspense>
  )
}

export { App }
