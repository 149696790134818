/** @jsx jsx */
import {jsx} from '@emotion/core'

import * as React from 'react'

const AlertContext = React.createContext()
AlertContext.displayName = 'AlertContext'


// https://www.yld.io/blog/global-notifications-with-reacts-context-api/
function AlertProvider({children}){

    const [text, setText] = React.useState(null);

    const removeText = () => setText(null);

    const addText = (message,title, status) => setText({ message,title, status });

    const [sidebarOpen,setSidebarOpen] = React.useState(false)
    const openClose = (openorclose)=>{
      setSidebarOpen(openorclose)
    }
    
      
    const [brands,setBrands] = React.useState({"brand":[],"platform":{}});
    const [ok,setOk] = React.useState(false)
    // const addBrand = (brand) => {
    //   setBrands({"brand":[...brands.brand, brand]})
    // }
    
    

    const contextValue = {
        text,
        addText: React.useCallback((message,title, status) => addText(message,title, status), []),
        removeText: React.useCallback(() => removeText(), []),
        brands,
        setBrands:React.useCallback((newItem) => setBrands(newItem), []),
        // cleanBrand: React.useCallback(() => cleanBrand(),[]),
        sidebarOpen,
        openClose:React.useCallback((openorclose) => openClose(openorclose),[]),
        ok,
        setOk:React.useCallback((v) => setOk(v),[]),
    };

    return (
        <AlertContext.Provider value={contextValue}>
          {children}
        </AlertContext.Provider>
    );
}

function useAlert() {
    const { text, addText, removeText } = React.useContext(AlertContext);
    return { text, addText, removeText };
}

function useSidebar() {
  const {brands, setBrands,sidebarOpen,openClose,ok,setOk} = React.useContext(AlertContext);
  return {brands, setBrands,sidebarOpen,openClose,ok,setOk}
}

export {AlertProvider,useAlert,useSidebar}