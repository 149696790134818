import * as React from 'react'
import {BrowserRouter as Router} from 'react-router-dom'
import {QueryClientProvider,QueryClient} from 'react-query'
import {AuthProvider} from './auth-context'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      useErrorBoundary: true,
      refetchOnWindowFocus: false,
      staleTime: 30000,
      retry(failureCount, error) {
        if (error.status === 404) return false
        else if (failureCount < 2) return true
        else return false
      },
    },
    // mutations: {
      // mutation options
    // },
    // queryCache:new QueryCache()
  }
})

function AppProviders({children}) {
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <AuthProvider>{children}</AuthProvider>
      </Router>
    </QueryClientProvider>
  )
}

export {AppProviders}
