/** @jsx jsx */
import {jsx} from '@emotion/core'

import * as React from 'react'
import * as auth from 'auth-provider'
import {client} from 'utils/api-client'
import {useAsync} from 'utils/hooks'
import {FullPageSpinner, FullPageErrorFallback} from 'components/lib'

async function getUser() {
  let user = null

  const token = await auth.getToken()
  if (token) {
    const data = await client('users/me', {token})
    // user = data.user
    user = data
    user['token'] = token
  }

  return user
}

const userPromise = getUser()

const AuthContext = React.createContext()
AuthContext.displayName = 'AuthContext'

function AuthProvider(props) {
  const {
    data: user,
    error,
    isLoading,
    isIdle,
    isError,
    isSuccess,
    run,
    setData,
    status,
  } = useAsync()
  
  React.useEffect(() => {
    run(userPromise)
  }, [run])

  const login = React.useCallback(
    form => auth.login(form).then(user => {setData(user);}),
    [setData],
  )
  const register = React.useCallback(
    form => auth.register(form).then(user => setData(user)),
    [setData],
  )
  const logout = React.useCallback(() => {
    auth.logout()
    setData(null)
  }, [setData])

  const value = React.useMemo(() => ({user, login, logout, register}), [
    login,
    logout,
    register,
    user,
  ])

  if (isLoading || isIdle) {
    return <FullPageSpinner />
  }

  if (isError) {
    return <FullPageErrorFallback error={error} />
  }

  if (isSuccess) {
    return <AuthContext.Provider value={value} {...props} />
  }

  throw new Error(`Unhandled status: ${status}`)
}

function useAuth() {
  const context = React.useContext(AuthContext)
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`)
  }
  return context
}

function useClient() {
  const {
    user
  } = useAuth()
  let token = ''
  if(user){
    token = user['token']
  }
  // let token = undefined
  // const user = useAuth();
  // if(user.hasOwnProperty('token')){
  //   token = user['token'] 
  // }
  // console.log("token:",token)
  return React.useCallback(
    (endpoint, config) => client(endpoint, {...config, token}),
    [token],
  )
  
}

function useClientNoToken(){
  return React.useCallback(
    (endpoint, config) => client(endpoint, {...config}),
    [],
  )
}

export {AuthProvider, useAuth, useClient,useClientNoToken}
